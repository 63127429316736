import { useTranslations } from "../../i18n";
import appRoutes from "../../constants/appRoutes";
import { NavbarItem, NavbarList, StyledNavLink } from "./Navbar.styles";

interface NavbarItemType {
  label: string;
  url: string;
}

const navbarItems: NavbarItemType[] = [
  {
    label: "navbar.about",
    url: appRoutes.about,
  },
  {
    label: "navbar.howItWorks",
    url: appRoutes.howItWorks,
  },
  {
    label: "navbar.catalog",
    url: appRoutes.catalog,
  },
  {
    label: "navbar.contact",
    url: appRoutes.contact,
  },
];

export const Navbar = () => {
  const { t } = useTranslations();

  return (
    <nav>
      <NavbarList>
        {navbarItems.map((item, key) => (
          <NavbarItem key={key}>
            <StyledNavLink href={item.url}>{t(item.label)}</StyledNavLink>
          </NavbarItem>
        ))}
      </NavbarList>
    </nav>
  );
};
