import { LanguageSelector } from "../LanguageSelector";
import { PageBackground } from "./PageBackground.styles";
import { PageLayoutWrapper } from "./PageWrapper.styles";

export function PageWrapper({ children }: { children: React.ReactNode }) {
  return (
    <PageLayoutWrapper>
      <LanguageSelector />
      {children}
      <PageBackground />
    </PageLayoutWrapper>
  );
}
