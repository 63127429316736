"use client";

import styled from "styled-components";
import theme from "../../constants/theme";

const LETTER_SPACING = ".2rem";

export const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  font-weight: ${theme.fontWeights[1]};
  letter-spacing: ${LETTER_SPACING};
  line-height: 1.5;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  color: ${theme.colors.fgBold};
`;
