"use client";

import styled from "styled-components";
import theme from "../../constants/theme";

export const SocialIconList = styled.ul`
  cursor: default;
  list-style: none;
  padding-left: 0;
`;

export const SocialIcon = styled.li`
  display: inline-block;
  padding: 0 0.75em 0 0;

  &:last-child {
    padding-right: 0;
  }

  a {
    border-radius: 100%;
    box-shadow: inset 0 0 0 1px ${theme.colors.border};
    display: inline-flex;
    height: 2.25rem;
    line-height: 2.25rem;
    text-align: center;
    width: 2.25rem;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${theme.colors.borderBg};
    }

    &:active {
      background-color: ${theme.colors.borderBgAlt};
    }

    .fa-icon {
      height: 1rem;
    }
  }
`;
