"use client";
import styled from "styled-components";
import theme from "../../constants/theme";
import Color from "color";
import { below } from "../../helpers/mediaQuery";

interface CardPageWrapperProps {
  fullWidth?: boolean;
}

export const CardPageWrapper = styled.div<CardPageWrapperProps>`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;

  position: relative;
  z-index: 1;
  max-width: 100%;

  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
  width: ${({ fullWidth }) => (fullWidth ? "80%" : "40rem")};
  max-width: 100%;
  background-color: ${Color(theme.colors.bg).fade(0.15).toString()};
  border-radius: ${theme.borderRadii[0]}px;

  ${below("sm")} {
    padding: 2rem;
  }
  ${below("xs")} {
    padding: 1.5rem;
  }
`;

export const GoBackButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
  font-size: 0.8rem;
  border-bottom-style: none;
  color: ${theme.colors.fgLight};

  &:hover {
    color: ${theme.colors.fg};
  }

  .fa-icon {
    height: 0.8rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
