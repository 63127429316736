"use client";
import styled from "styled-components";
import { below } from "../../helpers/mediaQuery";

const TOP_BAR_HEIGHT = "50px";

export const PageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: max(4rem, ${TOP_BAR_HEIGHT}) 2rem 4rem 2rem;
  z-index: 3;

  &:before {
    content: "";
    display: block;
  }

  ${below("xl")} {
    padding: max(3rem, ${TOP_BAR_HEIGHT}) 2rem 3rem 2rem;
  }

  ${below("sm")} {
    padding: max(2rem, ${TOP_BAR_HEIGHT}) 1rem 2rem 1rem;
  }

  ${below("xs")} {
    padding: max(1rem, ${TOP_BAR_HEIGHT}) 1rem 1rem 1rem;
  }
`;
