import {
  StepArrow,
  StepDescription,
  StepNumber,
  StepNumberWrapper,
  StepTitle,
  StepWrapper,
} from "./Steps.styles";

const arrowSvg = "/images/arrow-white.svg";

interface StepsProps {
  titles: string[];
  descriptions?: string[];
}

export const Steps = ({ titles = [], descriptions = [] }: StepsProps) => (
  <div>
    {titles.map((stepTitle, key) => (
      <StepWrapper key={key}>
        <StepNumberWrapper>
          <StepNumber>{`0${key + 1}`}</StepNumber>
          {key + 1 < titles.length && (
            <StepArrow src={arrowSvg} alt="Step arrow" />
          )}
        </StepNumberWrapper>
        <div>
          <StepTitle>{stepTitle}</StepTitle>
          <StepDescription>{descriptions[key] ?? ""}</StepDescription>
        </div>
      </StepWrapper>
    ))}
  </div>
);
