export const publicRoutes = {
  about: "/about",
  catalog: "/catalog",
  contact: "/contact",
  howItWorks: "/how-it-works",
};

export const BASE_PATH = "/";

export default { ...publicRoutes };
