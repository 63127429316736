import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageWrapper } from "../PageWrapper";
import { BASE_PATH } from "../../constants/appRoutes";
import { CardContent, CardPageWrapper, GoBackButton } from "./CardPage.styles";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslations } from "../../i18n";

interface CardPageProps {
  title?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
}

export const CardPage = ({ children, title, fullWidth }: CardPageProps) => {
  const { t } = useTranslations();

  return (
    <PageWrapper>
      <CardPageWrapper fullWidth={fullWidth}>
        <CardContent>
          <GoBackButton href={BASE_PATH}>
            <FontAwesomeIcon className="fa-icon" icon={faChevronLeft} />
            {t("actions.goBack")}
          </GoBackButton>
          {title && <h2 className="major">{title}</h2>}
          {children}
        </CardContent>
      </CardPageWrapper>
    </PageWrapper>
  );
};
