import { useTranslations } from "../../i18n";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import { Flex } from "../Flex";
import { Pill } from "../Pill";
import {
  BikeImg,
  BikeInfoRow1,
  BikeInfoRow2,
  BikeInfoText,
  BikeLabelText,
  BikeNameText,
  CardContent,
  CardWrapper,
} from "./BikeCard.styles";

export interface Bike {
  imageUrl: string;
  brand: string;
  model: string;
  size: string;
  wheelDiameter: string;
  gear: string;
  fork: string;
  type: string;
}

export const BikeCard = ({
  imageUrl,
  brand,
  model,
  size,
  wheelDiameter,
  gear,
  fork,
  type,
}: Bike) => {
  const { t } = useTranslations();
  const { isBelow } = useMediaQueries();

  return (
    <CardWrapper>
      <BikeImg src={imageUrl} alt={`${brand}${model} photo`} />
      <CardContent>
        <BikeNameText>{`${brand} ${model}`}</BikeNameText>
        <Flex justifyContent="space-between" column={isBelow.md}>
          <BikeInfoRow1 gap={[1, 1]} alignItems="baseline">
            <BikeLabelText>{t("catalog.bikeCard.size")}</BikeLabelText>
            <BikeInfoText>{size}</BikeInfoText>
          </BikeInfoRow1>
          <BikeInfoRow1 gap={[1, 1]} alignItems="baseline">
            <BikeLabelText>Ø {t("catalog.bikeCard.wheel")}</BikeLabelText>
            <BikeInfoText>{wheelDiameter}</BikeInfoText>
          </BikeInfoRow1>
        </Flex>
        <Flex
          justifyContent="space-between"
          column={isBelow.lg}
          gap={isBelow.lg ? [1, 1] : undefined}
        >
          <BikeInfoRow2 column>
            <BikeLabelText>{t("catalog.bikeCard.gear")}</BikeLabelText>
            <BikeInfoText>{gear}</BikeInfoText>
          </BikeInfoRow2>
          <BikeInfoRow2 column>
            <BikeLabelText>{t("catalog.bikeCard.fork")}</BikeLabelText>
            <BikeInfoText>{fork}</BikeInfoText>
          </BikeInfoRow2>
        </Flex>
        <Flex>
          <Pill>{type}</Pill>
        </Flex>
      </CardContent>
    </CardWrapper>
  );
};
