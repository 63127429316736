import { DefaultTheme } from "styled-components";

import { FontFamilyVariants, PaletteColors } from "../constants/theme";

interface StyledComponentProps {
  theme: DefaultTheme;
}

export const spaces =
  (index: number) =>
  ({ theme }: StyledComponentProps) =>
    theme.spaces[index];

export const borderRadii =
  (index: number) =>
  ({ theme }: StyledComponentProps) =>
    theme.borderRadii[index];

export const colors =
  (key: PaletteColors) =>
  ({ theme }: StyledComponentProps) =>
    theme.colors[key];

export const fontSizes =
  (index: number) =>
  ({ theme }: StyledComponentProps) =>
    theme.fontSizes[index];

export const fontWeights =
  (index: number) =>
  ({ theme }: StyledComponentProps) =>
    theme.fontWeights[index];

export const lineHeights =
  (index: number) =>
  ({ theme }: StyledComponentProps) =>
    theme.lineHeights[index];

export const columnGaps =
  (index: number) =>
  ({ theme }: StyledComponentProps) =>
    theme.grid.columnGaps[index];

export const fontFamilies =
  (key: FontFamilyVariants) =>
  ({ theme }: StyledComponentProps) =>
    theme.fontFamilies[key];
