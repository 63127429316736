import React from "react";

// import { Link } from "@/mayaUI/Link";

const ESCAPED_LINE_BREAK = /\n/g;
const ESCAPED_ASTERISK = /ESCAPEDASTERISK/g;
const ESCAPED_ASTERISK_REGEXP = /\\\*/g;
const ESCAPED_UNDERSCORE = /ESCAPEDUNDERSCORE/g;
const ESCAPED_UNDERSCORE_REGEXP = /\\_/g;
const MARKDOWN_BOLD_REGEXP = /(\*\*|__)(.*?)\1/g;
const MARKDOWN_ITALIC_REGEXP = /(\*|_)(.*?)\1/g;
/**
 * Matches either `[link name](https://this.is.a.url)`
 * or `[link name]{onClick='clickHandlerName'}`
 * or even `[link name](https://this.is.a.url){onClick='clickHandlerName'}`
 * more info here - https://regexr.com/6i1h1
 */
const MARKDOWN_LINK_REGEXP =
  /[\\[]{1}([^\]]+)[\]]{1}(?:[\\(]{1}([^\\)\\"]+)?[\\)]{1})?(?:[\\{]{1}onClick='([^\\}\\"]+)?'[\\}]{1})?/g;

const HTMLToReact = (
  el: Node,
  index: number = 0,
  replaceFunctions: Record<string, React.MouseEventHandler<HTMLElement>> = {},
  openLinksInCurrentTab: boolean = false
) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return <React.Fragment key={index}>{el.textContent ?? ""}</React.Fragment>;
  }

  let children: JSX.Element[] = [];

  if (el.childNodes) {
    children = Array.from(el.childNodes)
      .map((element, index) =>
        HTMLToReact(element, index, replaceFunctions, openLinksInCurrentTab)
      )
      .flat();
  }

  switch (el.nodeName) {
    case "BR":
      return <br key={index} />;
    case "EM":
      return <em key={index}>{children}</em>;
    case "STRONG":
      return <strong key={index}>{children}</strong>;
    // case "A": {
    //   const element = el as HTMLElement;
    //   const onClickName = element.getAttribute("data-onclick") ?? "";
    //   const onClick = replaceFunctions[onClickName];
    //   const url = element.getAttribute("href") ?? "";

    //   return (
    //     <Link
    //       key={index}
    //       openInNewTab={!openLinksInCurrentTab}
    //       url={url ? url : undefined}
    //       onClick={onClick}
    //       keepOnClick={!!onClick}
    //     >
    //       {children}
    //     </Link>
    //   );
    // }
    default:
      return children;
  }
};

const markdownToHTML = (markdown: string) => {
  return markdown
    .replace(ESCAPED_UNDERSCORE_REGEXP, ESCAPED_UNDERSCORE.source)
    .replace(ESCAPED_ASTERISK_REGEXP, ESCAPED_ASTERISK.source)
    .replace(MARKDOWN_BOLD_REGEXP, "<strong>$2</strong>")
    .replace(MARKDOWN_ITALIC_REGEXP, "<em>$2</em>")
    .replace(MARKDOWN_LINK_REGEXP, `<a href="$2" data-onclick="$3">$1</a>`)
    .replace(ESCAPED_ASTERISK, "*")
    .replace(ESCAPED_UNDERSCORE, "_")
    .replace(ESCAPED_LINE_BREAK, "<br>");
};

/**
 *
 * @param markdown
 * @param {Object} replaceFunctions an object with onClick handlers
 * e.g. if the markdown is [link text]{onClick='handlerName'}
 * the replaceFunctions object should be { handlerName: <handler function> }
 */
export const markdownToReact = (
  markdown: string,
  replaceFunctions?: Record<string, React.MouseEventHandler<HTMLElement>>,
  openLinksInCurrentTab?: boolean
) => {
  const html = markdownToHTML(markdown);
  const domParser = new DOMParser();
  const parsed = domParser.parseFromString(html, "text/html");
  return HTMLToReact(parsed.body, 0, replaceFunctions, openLinksInCurrentTab);
};
