"use client";

import styled from "styled-components";

export const AboutImageWrapper = styled.div`
  overflow: hidden;
  height: 180px;
  max-width: 100%;
  position: relative;
`;

export const AboutImage = styled.img`
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: -50%;
`;
