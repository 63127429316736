import appRoutes from "../../constants/appRoutes";
import { CardPage } from "../../components/CardPage";
import { useTranslations } from "../../i18n";
import bikesData from "../../data/bikes.json";
import { Flex } from "../../components/Flex";
import { Bike, BikeCard } from "../../components/BikeCard";
import { DescriptionText } from "./Catalog.styles";
import { CONTACT_PHONE } from "../../constants/contactInfo";

export function Catalog() {
  const { t } = useTranslations();

  const bikes = bikesData as Bike[];

  return (
    <CardPage title={t("catalog.title")} fullWidth>
      <DescriptionText>
        {t("catalog.description", { phone: CONTACT_PHONE })}
      </DescriptionText>
      <Flex justifyContent="space-around" gap={[6, 6]} $wrap>
        {bikes.map((bike, key) => (
          <BikeCard key={key} {...bike} />
        ))}
      </Flex>
      <p style={{ whiteSpace: "pre-line", fontWeight: 400 }}>
        {t("doubt.anyDoubt")}&nbsp;
        <a href={appRoutes.contact}>{t("doubt.contactUs")}</a>
      </p>
    </CardPage>
  );
}
