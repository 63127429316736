"use client";

import styled from "styled-components";
import theme from "../../constants/theme";
import { below } from "../../helpers/mediaQuery";

const BORDER_WIDTH = 1;
const LETTER_SPACING = "0.2rem";

export const NavbarList = styled.ul`
  display: flex;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  border: solid ${BORDER_WIDTH}px ${theme.colors.border};
  border-radius: ${theme.borderRadii[0]}px;

  ${below("xs")} {
    flex-direction: column;
    min-width: 10rem;
    max-width: 100%;
  }
`;

export const NavbarItem = styled.li`
  padding-left: 0;
  border-left: solid ${BORDER_WIDTH}px ${theme.colors.border};

  &:first-child {
    border-left: 0;
  }

  ${below("xs")} {
    border-left: 0;
    border-top: solid ${BORDER_WIDTH}px ${theme.colors.border};

    &:first-child {
      border-top: 0;
    }
  }
`;

export const StyledNavLink = styled.a`
  display: block;
  min-width: 7.5rem;
  height: 2.75rem;
  line-height: 2.75rem;
  padding: 0 1.25rem 0 calc(1.25rem + ${LETTER_SPACING});
  text-transform: uppercase;
  letter-spacing: ${LETTER_SPACING};
  font-size: 0.8rem;
  border-bottom: 0;

  &:hover {
    background-color: ${theme.colors.borderBg};
  }

  &:active {
    background-color: ${theme.colors.borderBgAlt};
  }

  ${below("xs")} {
    height: 3rem;
    line-height: 3rem;
    min-width: 0;
    width: 100%;
  }
`;
