import Color from "color";
import { DefaultTheme } from "styled-components";

export const breakpoints = {
  // eslint-disable-next-line sort/object-properties
  xxs: 360,
  xs: 480,
  sm: 736,
  md: 980,
  lg: 1280,
  xl: 1680,
};

const spaces = [
  0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96,
] as const;
export type Spaces = typeof spaces;

const fontSizes = [10, 12, 14, 16, 20, 24, 36, 42] as const;
export type FontSizes = typeof fontSizes;

const fontWeights = [200, 400, 600, 800] as const;
export type FontWeights = typeof fontWeights;

const fontFamilies = {
  default: "'Source Sans Pro', sans-serif",
  fixed: "'Courier New', monospace",
} as const;
export type FontFamilies = typeof fontFamilies;
export type FontFamilyVariants = keyof FontFamilies;

const borderRadii = [4, 8] as const;
export type BorderRadii = typeof borderRadii;

export const paletteColors = {
  black: "#000000",
  gray900: "#1B1F22",
  lightGreen600: "#D4D8D4",
  lightGreen800: "#6B766B",
  lightGreen900: "#838E83",
  green900: "#283618",
  white: "#FFFFFF",
};

const shadowColors = {
  bgOverlayShadow: "rgba(19,21,25,0.5)",
};

const semanticColors = {
  bg: paletteColors.gray900,
  border: paletteColors.white,
  borderBg: Color(paletteColors.white).fade(0.075).toString(),
  borderBgAlt: Color(paletteColors.white).fade(0.175).toString(),
  fg: paletteColors.white,
  fgBold: paletteColors.white,
  fgLight: Color(paletteColors.white).fade(0.5).toString(),
};

const accentColor = paletteColors.green900;
const onAccentColor = paletteColors.white;
const accentLightColor = paletteColors.lightGreen900;

const themeColors = {
  // accent color
  accent: accentColor,
  onAccent: onAccentColor,
  accentLight: accentLightColor,
};

const colors = {
  ...paletteColors,
  ...shadowColors,
  ...semanticColors,
  ...themeColors,
};
export type PaletteColors = keyof typeof colors;

const theme: DefaultTheme = {
  borderRadii,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  spaces,
};

export default theme;
