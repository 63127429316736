import styled from "styled-components";
import theme from "../../constants/theme";

export const PageBackground = styled.div`
  &:before {
    background: ${theme.colors.bgOverlayShadow};
  }

  transform: scale(1);
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    transition: background-color 2.5s ease-in-out;
    transition-delay: 0.75s;
    background-image: linear-gradient(
        to top,
        rgba(19, 21, 25, 0.5),
        rgba(19, 21, 25, 0.5)
      ),
      url(/images/overlay.png);
    background-size: auto, 256px 256px;
    background-position: center, center;
    background-repeat: no-repeat, repeat;
    z-index: 2;
  }

  &:after {
    transform: scale(1.125);
    transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out;
    background-image: url("/images/sella-landscape.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;
