import { SupportedLocale, useTranslations } from "../../i18n";
import { SUPPORTED_LOCALES } from "../../i18n/config";
import {
  LanguageSelectorList,
  LanguageItem,
  LanguageSeparator,
} from "./LanguageSelector.styles";
import { Fragment } from "react";

export const LanguageSelector = () => {
  const { locale: currentLocale, setLocale } = useTranslations();

  const handleLanguageChange = (locale: SupportedLocale) => {
    setLocale(locale);
  };

  return (
    <LanguageSelectorList>
      {SUPPORTED_LOCALES.map((locale, index) => (
        <Fragment key={index}>
          {index > 0 && <LanguageSeparator />}
          <LanguageItem
            selected={locale === currentLocale}
            onClick={() => handleLanguageChange(locale)}
          >
            {locale}
          </LanguageItem>
        </Fragment>
      ))}
    </LanguageSelectorList>
  );
};
