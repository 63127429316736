import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { PageWrapper } from "../../components/PageWrapper";

export function Home() {
  return (
    <PageWrapper>
      <Header />
      <Footer />
    </PageWrapper>
  );
}
