import React, { createContext, useContext } from "react";
import { TranslationsContextType } from "./types";
import { DEFAULT_LOCALE } from "./config";
import { useTranslationsController } from "./translations.controller";

export const TranslationsContext = createContext<TranslationsContextType>({
  locale: DEFAULT_LOCALE,
  setLocale: () => {
    throw new Error("translations is not implemented");
  },
  t: () => {
    throw new Error("t is not implemented");
  },
  tmd: () => {
    throw new Error("tmd is not implemented");
  },
});

export const TranslationsProvider = ({ children }: React.PropsWithChildren) => {
  const viewModel = useTranslationsController();

  return (
    <TranslationsContext.Provider value={viewModel}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = () => useContext(TranslationsContext);
