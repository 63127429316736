import {
  aboveQuery,
  belowQuery,
  breakpointType,
} from "../../helpers/mediaQuery";
import { createContext, useContext } from "react";
import useMatchMedia from "react-use-match-media";

type MediaQueriesContextType = {
  isAbove: { [key: string]: boolean };
  isBelow: { [key: string]: boolean };
  isBetween: (
    breakpointFrom: breakpointType,
    breakpointTo: breakpointType
  ) => boolean;
};

const MediaQueriesContext = createContext<MediaQueriesContextType>({
  isAbove: {},
  isBelow: {},
  isBetween: () => false,
});

export const MediaQueriesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isAbove = {
    /* eslint-disable sort/object-properties */
    xxs: useMatchMedia(aboveQuery("xxs")),
    xs: useMatchMedia(aboveQuery("xs")),
    sm: useMatchMedia(aboveQuery("sm")),
    md: useMatchMedia(aboveQuery("md")),
    lg: useMatchMedia(aboveQuery("lg")),
    xl: useMatchMedia(aboveQuery("xl")),
  };

  const isBelow = {
    /* eslint-disable sort/object-properties */
    xxs: useMatchMedia(belowQuery("xxs")),
    xs: useMatchMedia(belowQuery("xs")),
    sm: useMatchMedia(belowQuery("sm")),
    md: useMatchMedia(belowQuery("md")),
    lg: useMatchMedia(belowQuery("lg")),
    xl: useMatchMedia(belowQuery("xl")),
  };

  const isBetween = (
    breakpointFrom: breakpointType,
    breakpointTo: breakpointType
  ) => isAbove[breakpointFrom] && isBelow[breakpointTo];

  return (
    <MediaQueriesContext.Provider
      value={{
        isAbove,
        isBelow,
        isBetween,
      }}
    >
      {children}
    </MediaQueriesContext.Provider>
  );
};

export const MediaQueriesConsumer = MediaQueriesContext.Consumer;

export const useMediaQueries = () => useContext(MediaQueriesContext);
