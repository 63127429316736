import {
  BrandContent,
  ContentDescriptionText,
  ContentTitleText,
  ContentWrapper,
  HeaderWrapper,
  InnerContent,
  LogoImg,
  LogoWrapper,
} from "./Header.styles";
import { Navbar } from "../Navbar";
import { useTranslations } from "../../i18n";

export const Header = () => {
  const { t } = useTranslations();

  return (
    <HeaderWrapper>
      <BrandContent>
        <LogoWrapper>
          <LogoImg
            src="/images/logo-no-text-no-line.png"
            alt="Sky pedals logo"
          />
        </LogoWrapper>
        <ContentWrapper>
          <InnerContent>
            <ContentTitleText>{t("brand.name")}</ContentTitleText>
            <ContentDescriptionText>
              {t("brand.description")}
            </ContentDescriptionText>
          </InnerContent>
        </ContentWrapper>
      </BrandContent>
      <Navbar />
    </HeaderWrapper>
  );
};
