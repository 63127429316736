import appRoutes from "../../constants/appRoutes";
import { AboutImageWrapper, AboutImage } from "./About.styles";
import { CardPage } from "../../components/CardPage";
import { useTranslations } from "../../i18n";

export function About() {
  const { t } = useTranslations();

  return (
    <CardPage title={t("about.title")}>
      <AboutImageWrapper>
        <AboutImage
          src="/images/sella-repetidor-landscape.jpeg"
          alt="Sella photo"
          sizes="(max-width: 768px) 70vw, 80vw"
        />
      </AboutImageWrapper>
      <p style={{ whiteSpace: "pre-line", fontWeight: 400 }}>
        {t("about.description")}
        <br />
        <br />
        {t("doubt.anyDoubt")}&nbsp;
        <a href={appRoutes.contact}>{t("doubt.contactUs")}</a>
      </p>
    </CardPage>
  );
}
