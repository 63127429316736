import { ThemeProvider } from "styled-components";
import theme from "../constants/theme";
import { Router } from "./Router";
import appRoutes from "../constants/appRoutes";
import { Route } from "react-router-dom";
import { GlobalStyles } from "./GlobalStyles.styles";
import { About } from "../pages/About";
import { Contact } from "../pages/Contact";
import { HowItWorks } from "../pages/HowItWorks";
import { MediaQueriesProvider } from "../hooks/useMediaQueries";
import { HelmetProvider } from "react-helmet-async";
import { Home } from "../pages/Home/Home";
import { TranslationsProvider } from "../i18n";
import { Catalog } from "../pages/Catalog";

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <TranslationsProvider>
        <HelmetProvider>
          <MediaQueriesProvider>
            <Router>
              <Route path={"/"} element={<Home />} />
              <Route path={appRoutes.about} element={<About />} />
              <Route path={appRoutes.howItWorks} element={<HowItWorks />} />
              <Route path={appRoutes.catalog} element={<Catalog />} />
              <Route path={appRoutes.contact} element={<Contact />} />
            </Router>
          </MediaQueriesProvider>
        </HelmetProvider>
      </TranslationsProvider>
    </ThemeProvider>
  );
}
