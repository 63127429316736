import { useMemo, useState } from "react";
import { SupportedLocale, Translation, TranslationsContextType } from "./types";
import { DEFAULT_LOCALE } from "./config";
import { t as tFunction, tmd as tmdFunction } from "./helpers";
import en from "./locales/en.json";
import es from "./locales/es.json";
import ca from "./locales/ca.json";

const allTranslations: { [key in SupportedLocale]: Translation } = {
  en,
  es,
  ca,
};

export const useTranslationsController = (): TranslationsContextType => {
  const [locale, setLocale] = useState<SupportedLocale>(DEFAULT_LOCALE);

  const t = useMemo(() => {
    const currentTranslations = allTranslations[locale];
    return tFunction(currentTranslations);
  }, [locale]);

  const tmd = useMemo(() => {
    const currentTranslations = allTranslations[locale];
    return tmdFunction(currentTranslations);
  }, [locale]);

  return {
    locale,
    setLocale,
    t,
    tmd,
  };
};
