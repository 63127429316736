import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Label } from "../../components/Label";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useTranslations } from "../../i18n";
import { SocialIconList, SocialIcon } from "./Contact.styles";
import { CardPage } from "../../components/CardPage";
import {
  CONTACT_ADDRESS,
  CONTACT_ADDRESS_URL,
  CONTACT_EMAIL,
  CONTACT_INSTAGRAM_URL,
  CONTACT_PHONE,
} from "../../constants/contactInfo";

export function Contact() {
  const { t } = useTranslations();

  return (
    <CardPage title={t("contact.title")}>
      <div>
        <Label htmlFor="phone">{t("contact.phone")}</Label>
        <p id="phone">
          <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a>
        </p>
      </div>
      <div>
        <Label htmlFor="email">{t("contact.email")}</Label>
        <p id="email">
          <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
        </p>
      </div>
      <div>
        <Label htmlFor="address">{t("contact.address")}</Label>
        <p id="address">
          <a href={CONTACT_ADDRESS_URL} target="_blank" rel="noreferrer">
            {CONTACT_ADDRESS}
          </a>
        </p>
      </div>
      <SocialIconList>
        <SocialIcon>
          <a href={CONTACT_INSTAGRAM_URL} target="_blank" rel="noreferrer">
            <FontAwesomeIcon className="fa-icon" icon={faInstagram} />
          </a>
        </SocialIcon>
      </SocialIconList>
    </CardPage>
  );
}
