import styled from "styled-components";

import { Flex } from "../../components/Flex";
import {
  borderRadii,
  colors,
  fontSizes,
  fontWeights,
  spaces,
} from "../../helpers/styledComponents";

export const StepWrapper = styled(Flex)`
  margin-bottom: ${spaces(4)}px;
`;

export const StepNumberWrapper = styled.div`
  width: ${spaces(7)}px;
  margin-right: ${spaces(7)}px;
`;

export const StepNumber = styled.div`
  text-align: center;
  font-weight: ${fontWeights(3)};
  line-height: ${spaces(7)}px;
  width: ${spaces(7)}px;
  height: ${spaces(7)}px;

  color: ${colors("onAccent")};
  background-color: ${colors("accentLight")};
  border-radius: ${borderRadii(0)}px;
`;

const ARROW_HEIGHT = 180;

export const StepArrow = styled.img`
  display: block;
  margin: ${spaces(3)}px auto;
  max-height: ${ARROW_HEIGHT}px;
`;

export const StepTitle = styled.h3`
  margin-top: 0;
  font-size: ${fontSizes(5)}px;
`;

export const StepDescription = styled.p`
  font-size: ${fontSizes(2)}px;
  font-weight: ${fontWeights(0)};
`;
