import styled, { css } from "styled-components";
import theme from "../../constants/theme";

export const LanguageSelectorList = styled.ul`
  position: absolute;
  top: 10px;
  right: 16px;
  z-index: 10;

  display: flex;
  align-items: center;
  gap: 12px;
  color: ${theme.colors.white};
`;

interface LanguageItemProps {
  selected?: boolean;
}
export const LanguageItem = styled.li<LanguageItemProps>`
  text-transform: uppercase;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      font-weight: ${theme.fontWeights[2]};
    `}
`;

export const LanguageSeparator = styled.li`
  height: 20px;
  width: 1px;
  background: ${theme.colors.fgLight};
`;
