"use client";

import styled from "styled-components";
import theme from "../../constants/theme";

export const FooterWrapper = styled.footer`
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  width: 100%;
  max-width: 100%;
  margin-top: 2rem;
  text-align: center;
  font-weight: ${theme.fontWeights[2]};
`;

export const CopyrightText = styled.p`
  letter-spacing: 0.2rem;
  font-size: 0.6rem;
  opacity: 0.75;
  margin-bottom: 0;
  text-transform: uppercase;
`;
