import styled from "styled-components";
import { Flex } from "../Flex";
import {
  borderRadii,
  colors,
  fontSizes,
  fontWeights,
  spaces,
} from "../../helpers/styledComponents";
import { below } from "../../helpers/mediaQuery";

export const CardWrapper = styled(Flex).attrs({
  column: true,
  justifyContent: "space-between",
})`
  background-color: ${colors("white")};
  border-radius: ${borderRadii(1)}px;
  overflow: hidden;
  width: 460px;

  ${below("lg")} {
    width: calc(50% - 12px);
  }

  ${below("md")} {
    width: 100%;
  }
`;

export const BikeImg = styled.img`
  /* max-width: 600px; */
  object-fit: contain;
  height: 300px;

  ${below("lg")} {
    height: unset;
  }
`;

export const CardContent = styled(Flex).attrs({ column: true, gap: [2, 2] })`
  padding: ${spaces(4)}px;
  background-color: ${colors("lightGreen600")};
  color: ${colors("black")};
`;

export const BikeNameText = styled.p`
  font-size: ${fontSizes(4)}px;
  margin: 0;
`;

export const BikeLabelText = styled.label`
  color: ${colors("lightGreen800")};
  font-size: ${fontSizes(0)}px;
  font-weight: ${fontWeights(2)};
  text-transform: uppercase;
`;

export const BikeInfoText = styled.span`
  font-size: ${fontSizes(2)}px;
  font-weight: ${fontWeights(0)};
`;

export const BikeInfoRow1 = styled(Flex)`
  width: 50%;

  ${below("md")} {
    width: 100%;
  }
`;

export const BikeInfoRow2 = styled(Flex)`
  width: 50%;

  ${below("lg")} {
    width: 100%;
  }
`;
