"use client";

import { createGlobalStyle, css } from "styled-components";
import { below } from "../helpers/mediaQuery";
import theme from "../constants/theme";

const BORDER_WIDTH = "1px";
const ELEMENT_MARGIN = "2rem";
const LETTER_SPACING = "0.2rem";
const LETTER_SPACING_HEADING = "0.5rem";

// prettier-ignore
const resetLayout = css`
	// meyerweb.com/eric/tools/css/reset v2.0 | 20110126 | License: none (public domain)
	html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}body{-webkit-text-size-adjust:none}

	*, *:before, *:after {
		box-sizing: border-box;
	}
`;

const logoFont = css`
  @font-face {
    font-family: "Bukhari Script";
    src: url("/fonts/bukhari-script.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
`;

export const GlobalStyles = createGlobalStyle`
	${resetLayout}
	${logoFont}

	html {
		font-size: 16pt;

		${below("xl")} {
			font-size: 12pt;
		}

		${below("sm")} {
			font-size: 11pt;
		}

		${below("xxs")} {
			font-size: 10pt;
		}
	}
	
	body, input, select, textarea {
		color: ${theme.colors.fg};
		font-family: ${theme.fontFamilies.default};
		font-weight: ${theme.fontWeights[1]};
		font-size: 1rem;
		line-height: 1.65;
	}

	a {
		transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		border-bottom: dotted 1px ${theme.colors.fgLight};
		text-decoration: none;
		color: inherit;

		&:hover {
			border-bottom-color: transparent;
		}
	}

	strong, b {
		color: ${theme.colors.fgBold};
		font-weight: ${theme.fontWeights[2]};
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 ${ELEMENT_MARGIN} 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: ${theme.colors.fgBold};
		font-weight: ${theme.fontWeights[2]};
		line-height: 1.5;
		margin: 0 0 calc(${ELEMENT_MARGIN} * 0.5) 0;
		text-transform: uppercase;
		letter-spacing: ${LETTER_SPACING};

		a {
			color: inherit;
			text-decoration: none;
		}

		&.major {
			border-bottom: solid ${BORDER_WIDTH} ${theme.colors.border};
			width: max-content;
			padding-bottom: 0.5rem;
			margin: 0;
			max-width: 100%;
		}
	}

	h1 {
		font-size: 2.25rem;
		line-height: 1.3;
		letter-spacing: ${LETTER_SPACING_HEADING};
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4;
		letter-spacing: ${LETTER_SPACING_HEADING};
	}

	h3 {
		font-size: 1rem;
	}

	h4 {
		font-size: 0.8rem;
	}

	h5 {
		font-size: 0.7rem;
	}

	h6 {
		font-size: 0.6rem;
	}

	${below("sm")} {
		h1 {
			font-size: 1.75rem;
			line-height: 1.4;
		}

		h2 {
			font-size: 1.25em;
			line-height: 1.5;
		}
	}

	sub {
		font-size: 0.8rem;
		position: relative;
		top: 0.5rem;
	}

	sup {
		font-size: 0.8rem;
		position: relative;
		top: -0.5rem;
	}

	blockquote {
		border-left: solid (${BORDER_WIDTH} * 4) ${theme.colors.border};
		font-style: italic;
		margin: 0 0 ${ELEMENT_MARGIN} 0;
		padding: calc(${ELEMENT_MARGIN} / 4) 0 calc(${ELEMENT_MARGIN} / 4) ${ELEMENT_MARGIN};
	}

	code {
		background: ${theme.colors.borderBg};
		border-radius: ${theme.borderRadii[0]};
		font-family: ${theme.fontFamilies.fixed};
		font-size: 0.9rem;
		margin: 0 0.25rem;
		padding: 0.25rem 0.65rem;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: ${theme.fontFamilies.fixed};
		font-size: 0.9rem;
		margin: 0 0 ${ELEMENT_MARGIN} 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1rem 1.5rem;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid ${BORDER_WIDTH} ${theme.colors.border};
		margin: (${ELEMENT_MARGIN} * 1.375) 0;
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}
`;
