import appRoutes from "../../constants/appRoutes";
import { CardPage } from "../../components/CardPage";
import { useTranslations } from "../../i18n";
import { Steps } from "../../components/Steps";
import { DescriptionText } from "./HowItWorks.styles";
import { CONTACT_PHONE } from "../../constants/contactInfo";

const stepNumbers = [1, 2, 3, 4];

export function HowItWorks() {
  const { t } = useTranslations();

  const descriptions = [
    {
      index: 1,
    },
    {
      index: 2,
      replaceProps: { phone: CONTACT_PHONE },
    },
    {
      index: 3,
    },
    {
      index: 4,
    },
  ];

  return (
    <CardPage title={t("howItWorks.title")}>
      <DescriptionText>{t("howItWorks.description")}</DescriptionText>
      <Steps
        titles={stepNumbers.map((step) => t(`howItWorks.step${step}.title`))}
        descriptions={descriptions.map(({ index, replaceProps }) => {
          console.log({ props: { ...replaceProps } });
          return t(`howItWorks.step${index}.description`, { ...replaceProps });
        })}
      />
      <DescriptionText>
        {t("doubt.anyDoubt")}&nbsp;
        <a href={appRoutes.contact}>{t("doubt.contactUs")}</a>
      </DescriptionText>
    </CardPage>
  );
}
