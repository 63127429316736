import { styled } from "styled-components";
import { Flex } from "../Flex";
import {
  borderRadii,
  colors,
  fontSizes,
  spaces,
} from "../../helpers/styledComponents";

export const PillWrapper = styled(Flex).attrs({
  alignItems: "center",
})`
  background-color: ${colors("white")};
  border-radius: ${borderRadii(1)}px;
  padding: ${spaces(2)}px;
`;

export const PillText = styled.span`
  font-size: ${fontSizes(1)}px;
  line-height: 16px;
`;
