"use client";

import styled from "styled-components";
import { above, below } from "../../helpers/mediaQuery";
import theme from "../../constants/theme";

const BORDER_WIDTH = 1;

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 55%
  );
  max-width: 100%;
  text-align: center;

  ${below("sm")} {
    gap: 2rem;
  }

  ${below("xs")} {
    padding: 1.5rem 0;
  }
`;

export const LogoWrapper = styled.div`
  width: 14rem;
  height: 14rem;
  line-height: 14rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: -${BORDER_WIDTH}px;

  ${below("sm")} {
    width: 8rem;
    height: 8rem;
    line-height: 8rem;
  }

  & > * {
    filter: invert(1);
  }
`;

export const LogoImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const ContentWrapper = styled.div`
  border-style: solid;
  border-color: ${theme.colors.border};
  border-top-width: ${BORDER_WIDTH}px;
  border-bottom-width: ${BORDER_WIDTH}px;
  max-width: 100%;
`;

export const InnerContent = styled.div`
  transition: max-height 0.75s ease, padding 0.75s ease,
    opacity 0.325s ease-in-out;
  transition-delay: 0.25s;
  padding: 3rem 2rem;
  max-height: 40rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${below("sm")} {
    padding: 2.5rem 1rem;
  }

  ${below("xs")} {
    padding: 2.5rem 0;
  }
`;
export const ContentTitleText = styled.h1`
  font-family: "Bukhari Script", "Source Sans Pro", sans-serif;
  text-transform: capitalize;
  letter-spacing: normal;
  font-weight: ${theme.fontWeights[1]};

  ${above("sm")} {
    font-size: 4rem;
  }

  ${above("xs")} {
    font-size: 3rem;
  }
`;

export const ContentDescriptionText = styled.p`
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  line-height: 2;
  margin: 0;

  ${below("sm")} {
    line-height: 1.875;
  }
`;

export const BrandContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
