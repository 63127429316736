import { get, isObject, isUndefined } from "lodash-es";
import React from "react";

import { isDevelopment, isTest } from "../config";

import { markdownToReact } from "./markdownToReact";
import { Translation } from "./types";

const COUNT_KEY = "count";
const ONE = "one";
const OTHER = "other";
const ZERO = "zero";

/**
 * Returns the "translation" value for the asset key
 * returns the "assetKey" if the translation does not exist
 * @param {String} assetKey
 * @param {Object} replaceProps: this object's keys are placeholders to be interpolated with object's values in the matching translation
 * @param {string} defaultValue: the defaultValue to show if the key was not found
 */
export const t =
  (translations: Translation) =>
  (
    assetKey: string,
    replaceProps?: Record<string, string | number>,
    defaultValue?: string
  ): string => {
    const value = get(translations, assetKey, defaultValue ?? assetKey);
    const rootKey = assetKey;

    if (!replaceProps) {
      return value;
    }

    const getPluralization = (
      count: number | string,
      accValue: object
    ): string | undefined => {
      const countString = count.toString();

      if (countString === "1") {
        return get(accValue, ONE) || get(accValue, OTHER);
      }

      if (countString === "0") {
        return get(accValue, ZERO) || get(accValue, OTHER);
      }

      return get(accValue, OTHER);
    };

    // if the "count" key is present, it should be applied first!
    // so we remove it from its current position in the keys array
    // and add it to the beginning
    const replacePropsKeys = Object.keys(replaceProps);
    const countKeyIndex = replacePropsKeys.indexOf(COUNT_KEY);
    if (countKeyIndex !== -1) {
      replacePropsKeys.splice(countKeyIndex, 1);
      replacePropsKeys.unshift(COUNT_KEY);
    }

    return replacePropsKeys.reduce((accValue: string | undefined, key) => {
      if (key == COUNT_KEY && isObject(accValue)) {
        accValue = getPluralization(replaceProps[COUNT_KEY], accValue);

        if (isUndefined(accValue)) {
          return isTest || isDevelopment
            ? `missing translation for ${rootKey}.${
                replaceProps[COUNT_KEY].toString() === "1" ? ONE : OTHER
              }`
            : rootKey;
        }
      }

      return (
        accValue?.replace(
          new RegExp(`{{${key}}}`, "g"),
          `${replaceProps[key]}`
        ) ?? assetKey
      );
    }, value);
  };

export interface TranslateWithMarkdownOptions {
  openLinksInCurrentTab?: boolean;
  replaceProps?: Record<string, string | number>;
  replaceFunctions?: Record<
    string,
    React.MouseEventHandler<HTMLElement> | VoidFunction
  >;
}

/**
 * Returns the "translation" value for the asset key and also transforms the markdown to a React Element
 * returns the "assetKey" if the translation does not exist
 * @param {String} assetKey
 * @param {Object} replaceProps: this object's keys are placeholders to be interpolated with object's values in the matching translation
 * @param {Object} replaceFunctions an object with onClick handlers
 * e.g. if the markdown is [link text]{onClick='handlerName'}
 * the replaceFunctions object should be { handlerName: <handler function> }
 */
export const tmd =
  (translations: Translation) =>
  (
    assetKey: string,
    {
      openLinksInCurrentTab = false,
      replaceFunctions = {},
      replaceProps = {},
    }: TranslateWithMarkdownOptions = {}
  ) => {
    return markdownToReact(
      t(translations)(assetKey, replaceProps),
      replaceFunctions,
      openLinksInCurrentTab
    );
  };
